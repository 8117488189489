<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'lucide-vue-next';
import { Direction } from '../../types/common';

withDefaults(defineProps<{
  buttonStyleDark?: boolean;
  floatingButtonColor?: string;
  hasFullHeightButtons?: boolean;
  left: boolean;
  neverHideButtons?: boolean;
  right: boolean;
  scrollDistance?: number;
  scrollerWrapperWidth: number;
  stylingButtonLeft?: string;
  stylingButtonRight?: string;
} &({
  backgroundColor: string;
  fadeOutEdges: true;
} | {
  backgroundColor?: string;
  fadeOutEdges?: false;
})>(), { buttonStyleDark: false,floatingButtonColor: 'white',hasFullHeightButtons: false,neverHideButtons: false,scrollDistance: 200,stylingButtonLeft: 'left-5',stylingButtonRight: 'right-5', })

defineEmits<{
  scrollNow: [ distance: number ];
}>();

const buttonClasses = computed(() => ({
  '!flex': __props.neverHideButtons,
  'button-dark': __props.buttonStyleDark,
  'scroller-button-full-height': __props.hasFullHeightButtons,
}));

const buttonConfig = computed(() => {
  const buttons = [
    {
      class: [
        `scroller-button-${Direction.LEFT}`,
        `scroller-button-${Direction.LEFT}-${__props.floatingButtonColor}`,
        __props.stylingButtonLeft,
        buttonClasses.value,
      ],
      direction: Direction.LEFT,
      isActive: !__props.left,
      scrollDistance: __props.scrollDistance
        ? -__props.scrollDistance
        : -__props.scrollerWrapperWidth,
    },
    {
      class: [
        `scroller-button-${Direction.RIGHT}`,
        `scroller-button-${Direction.RIGHT}-${__props.floatingButtonColor}`,
        __props.stylingButtonRight,
        buttonClasses.value,
      ],
      direction: Direction.RIGHT,
      isActive: !__props.right,
      scrollDistance: __props.scrollDistance || __props.scrollerWrapperWidth,
    },
  ];

  return buttons.filter(({ isActive }) => isActive);
});
</script>

<template>
  <div
    v-if="fadeOutEdges"
    class="absolute left-0 top-0 z-40 h-full w-8"
    :style="{ backgroundImage: `linear-gradient(90deg, ${backgroundColor}, transparent)` }"
  ></div>
  <div
    v-if="fadeOutEdges"
    class="absolute right-0 top-0 z-40 h-full w-8"
    :style="{ backgroundImage: `linear-gradient(-90deg, ${backgroundColor}, transparent)` }"
  ></div>
  <UiFloatingButton
    v-for="button in buttonConfig"
    :key="button.direction"
    class="scroller-button z-100 z-50 size-10 group-hover/scroller:flex"
    :class="button.class"
    :dark="buttonStyleDark"
    :icon="button.direction === Direction.LEFT ? ArrowLeftIcon : ArrowRightIcon"
    @click.stop="$emit('scrollNow', button.scrollDistance)"
  />
</template>

<style lang="scss" scoped>
.scroller-button {
  $root: &;

  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;

  &-full-height {
    top: 0;
    width: 48px;
    height: 100%;
    padding: 0 6px;

    &#{$root}-left {
      left: 0;

      &-white {
        background: linear-gradient(to right, #fff, #fff, rgb(255 255 255 / 0%));
      }

      &-purple-light {
        background: linear-gradient(to right, #E8E2FC, #E8E2FC, rgb(251 247 241 / 0%));
      }

      :deep(.floating-button-content) {
        @apply justify-start;
      }
    }

    &#{$root}-right {
      right: 0;

      &-white {
        background: linear-gradient(to left, #fff, #fff, rgb(255 255 255 / 0%));
      }

      &-purple-light {
        background: linear-gradient(to left, #E8E2FC, #E8E2FC, rgb(251 247 241 / 0%));
      }

      :deep(.floating-button-content) {
        @apply justify-end;
      }
    }

    ::after {
      display: none;
    }
  }
}
</style>
